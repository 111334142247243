span.p-calendar {
  width: 100%;
}

.p-calendar {
  position: relative;
  display: inline-flex;
}

.p-calendar .p-inputtext {
  flex: 1 1 auto;
  width: 1%;
}

.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-calendar-w-btn .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Fluid */
.p-fluid .p-calendar {
  display: flex;
}

.p-fluid .p-calendar .p-inputtext {
  width: 1%;
}

/* Datepicker */
.p-calendar .p-datepicker {
  min-width: 100%;
}

.p-datepicker {
  width: auto;
  position: absolute;
}

.p-datepicker-inline {
  display: inline-flex;
  position: static;
}

/* Header */
.p-datepicker-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-datepicker-header .p-datepicker-title {
  margin: 0 auto;
}

.p-datepicker-prev,
.p-datepicker-next {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

/* Multiple Month DatePicker */
.p-datepicker-multiple-month .p-datepicker-group-container {
  display: flex;
}

/* DatePicker Table */
.p-datepicker table {
  width: 100%;
  border-collapse: collapse;
}

.p-datepicker td > span {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

/* Month Picker */
.p-monthpicker-month {
  width: 33.3%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

/*  Button Bar */
.p-datepicker-buttonbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Time Picker */
.p-timepicker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-timepicker button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.p-timepicker > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* Touch UI */
.p-datepicker-touch-ui,
.p-calendar .p-datepicker-touch-ui {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 80vw;
  transform: translate(-50%, -50%);
}

/* Table starts */

.p-paginator .p-paginator-current {
	 margin-left: auto;
}

.p-progressbar {
	 height: 0.5rem;
	 background-color: #d8dadc;
}

.p-progressbar .p-progressbar-value {
	 background-color: #607d8b;
}

.table-header {
	 display: flex;
	 justify-content: space-between;
}

.p-calendar .p-datepicker {
	 min-width: 25rem;
}

.p-calendar .p-datepicker td {
	 font-weight: 400;
}

.p-datatable.p-datatable-employee .p-datatable-header {
	 padding: 1rem;
	 text-align: left;
	 font-size: 1.5rem;
}

.p-datatable.p-datatable-employee .p-paginator {
	 padding: 1rem;
}

.p-datatable.p-datatable-employee .p-paginator .p-paginator-current {
	 color: #6c757d;
}

.p-datatable.p-datatable-employee .p-paginator .p-paginator-first, .p-datatable.p-datatable-employee .p-paginator .p-paginator-prev, :host ::ng-deep .p-datatable.p-datatable-employee .p-paginator .p-paginator-next, :host ::ng-deep .p-datatable.p-datatable-employee .p-paginator .p-paginator-last {
	 color: #6c757d;
}

.p-datatable.p-datatable-employee .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
	 background: #6c757d;
	 border-color: #6c757d;
	 color: #fff;
}

.p-datatable.p-datatable-employee .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
	 background: #6c757d;
	 color: #fff;
}

.p-datatable.p-datatable-employee .p-datatable-thead > tr > th {
	 text-align: left;
}

.p-datatable.p-datatable-employee .p-datatable-tbody > tr > td {
	 cursor: auto;
}

.p-datatable.p-datatable-employee .p-dropdown-label:not(.p-placeholder) {
	 text-transform: uppercase;
}

.p-datatable-employee .p-datatable-tbody > tr > td .p-column-title {
	 display: none;
}

.p-datatable-employee .p-datatable-tbody > tr > td {
	 padding: 0.5rem 0.5rem;
}

.badge {
	 border-radius: 2px;
	 padding: 0.25em 0.5rem;
	 text-transform: uppercase;
	 font-weight: 700;
	 font-size: 12px;
	 letter-spacing: 0.3px;
}

.badge.status-closed {
	 background-color: #ffcdd2;
	 color: #c63737;
}

.badge.status-pending_confirmation {
	 background-color: #ffd8b2;
	 color: #805b36;
}

.badge.status-accepted {
	 background-color: #c8e6c9;
	 color: #256029;
}

@media screen and (max-width: 960px) {
	.p-datatable.p-datatable-employee .p-datatable-thead > tr > th, .p-datatable.p-datatable-employee .p-datatable-tfoot > tr > td {
		 display: none !important;
	}
	.p-datatable.p-datatable-employee .p-datatable-tbody > tr {
		 border-bottom: 1px solid var(--layer-2);
	}
	.p-datatable.p-datatable-employee .p-datatable-tbody > tr > td {
		 text-align: left;
		 display: block;
		 border: 0 none !important;
		 width: 100% !important;
		 float: left;
		 clear: left;
		 border: 0 none;
	}
	.p-datatable.p-datatable-employee .p-datatable-tbody > tr > td .p-column-title {
		 padding: 0.4rem;
		 min-width: 30%;
		 display: inline-block;
		 margin: -0.4rem 1rem -0.4rem -0.4rem;
		 font-weight: bold;
	}
	.p-datatable.p-datatable-employee .p-datatable-tbody > tr > td .p-progressbar {
		 margin-top: 0.5rem;
	}
}

/* Table ends */
