@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
:root {
  --primary-color: #5056CC;
  --sec-color: #00D079;
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
//   background-image: url(./assets/img/mockup2.png) no-repeat;
  background-color: #F8F8F8;
}

.accessWrapper {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(to right top, #32ff9b, #03d0c5, #00a5e0, #037de1, #5055cb);
  background-repeat: no-repeat;
  background-position: left;
//   background-size: 50% 100%;
  min-height: 100vh;
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
  overflow: auto;
}

.space{
  width: 10px
}

.invalid-feedback{
  display: block !important;
  margin-left: 15px;
}

// .accessWrapper .conteudo {
//   position: absolute;
//   top: -40px;
//   left: calc(47% + 60px);
//   text-align: center;
//   min-width: 20%;
//   transform: scale(80%);
//   height: 100%;
// }

.accessWrapper .conteudo h1 {
  color: #4D4D4D;
  font-size: 28px;
  font-weight: 700;
  font-family: 'Nunito Sans', sans-serif;
}

.accessWrapper .conteudo .extra {
  font-size: 14px;
  text-align: center;
  padding: 5px;
}
.accessWrapper .conteudo .extra a {
 color: #32ff9b !important;
  text-decoration: none;
}
.accessWrapper .conteudo .extra a:hover{
  text-decoration: underline;
}

form {
  text-align: left;
  width: 494px;
}

form .form-group {
  margin-bottom: 13px;
}

form .form-group label {
  display: block;
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}

form .form-group input {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 5px;
    font-size: 14px;
    padding: 17px 27px;
    font-family: "Nunito Sans", sans-serif;
    border: 0.7px solid #D2D2D2;
    color: #444444e0 !important;
}
input {
    height: 27px;
}
input:focus{
    outline: none;
}

form .form-group textarea {
  width: 100%;
  background-color: #ECF2F7;
  border-radius: 10px;
  font-size: 15px;
  padding: 10px 25px;
  font-family: 'Poppins', sans-serif;
  border: none;
}

form .form-group input.mid {
  width: 48%;
}

form .form-group button {
  color: #4D4D4D;
  background-color: #32ff9b;
  width: 498px;
  height: 45px;
  text-align: center;
  border: none;
  border-radius: 13px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  opacity: 1;
}

form .form-group button:disabled,
button[disabled]{
  background-color: #bcffde;
  color: #848484;
  opacity: 0.8;
  cursor: default;
}

form .options {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  align-items: center;
  margin: 15px 0 15px;
}

form .options label {
  color: #9BA1AE;
}

form .options label input {
  float: left;
  margin-right: 7px;
}


form .options a {
  color: var(--primary-color);
  font-weight: 700;
  text-decoration: none;
}

form .options a:hover {
  text-decoration: underline;
}

.p-selectbutton .p-button {
  background: var(--primary-color);
  border: none;
  color: #ffffff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  font-size: 0.7rem;
}

.p-selectbutton .p-button.p-highlight {
  background: #5055CB;
}

.p-selectbutton .p-button:enabled:active {
  border-color: transparent;
}

.p-selectbutton .p-button:enabled:hover {
  border-color: transparent;
}

.p-selectbutton .p-button:focus {
  box-shadow: none;
}

a > div.icon-check {
  width: 30px;
  display: contents;
}

.progress-spinner {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

@media (max-width: 1440px) {
    .accessWrapper .conteudo {
        position: relative;
        left: 0;
        width: 90vw;
        margin: 7%;
        transform: scale(90%) !important;
    }
}

@media (max-width: 768px) {
  .accessWrapper, .accessWrapper.mod2 {
    background: #4C55D1;
    align-items: flex-start;
    overflow-y: auto;
  }

  .accessWrapper .imagem {
    display: none;
  }

  .accessWrapper .conteudo {
    // position: relative;
    display: flex !important;
    justify-content: center !important;
    left: 0;
    width: 100%;
    margin: 0%;
    // transform: scale(80%) !important;
  }

  .accessWrapper .conteudo .logo {
    height: 45px;
  }

}

.p-dialog .p-dialog-content {
    background-image: initial;
    width: 700px;
    font-family: 'Nunito Sans';
    text-align: justify;
    padding: 30px;
}
.p-dialog .p-dialog-header{
 justify-content: center;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 1.60rem;
	font-family: 'Nunito Sans';
    padding-top: 13px;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-left: 112px;
    display: flex;
    position: absolute;
}

.p-dialog .p-dialog-header-icons {
    align-items: center;
    display: flex;
}
.ng-tns-c54-1.p-dialog-mask.ng-star-inserted {
    margin-left: -30vw;
}

.alpha-28 {
    font-weight: 300;
    color: #4D4D4D;
    font-size: 1.75rem;
    font-family: Nunito Sans;
}

.alpha-16 {
    color: #4D4D4D;
    font-size: 1em;
    font-family: Nunito Sans;
}

.alpha-14 {
    color: #4D4D4D;
    font-size: 0.875em;
    font-family: Nunito Sans;
}

.cursor {
    cursor: pointer;
}

/* enable absolute positioning */
.inner-addon {
    position: relative;
}

/* style icon */
.inner-addon .bi {
    position: absolute;
    padding: 6px;
    cursor: pointer;
}

/* align icon */
.right-addon .bi { right: 0px;}

/* add padding  */
.right-addon input { padding-right: 30px; }
